import React from "react"
import { graphql } from "gatsby"

import { EventCard, Filler, Layout, PastEventCard, SEO } from "../components"

const Performances = ({ data, location }) => {
  function getCurrentDate() {
    const d = new Date()
    let month = (d.getMonth() + 1).toString()
    if (month.length < 2) {
      month = `0${month}`
    }
    let day = d.getDate().toString()
    if (day.length < 2) {
      day = `0${day}`
    }
    return `${d.getFullYear()}-${month}-${day}`
  }
  const siteTitle = data.site.siteMetadata.title
  const ascendingPerformance = data.ascend.edges
  const performance = ascendingPerformance.sort(function (a, b) {
    const nameA = a.node.childMarkdownRemark.frontmatter.date
    const nameB = b.node.childMarkdownRemark.frontmatter.date
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })
  const descendingPerformance = data.descend.edges
  const reversePerformance = descendingPerformance.sort(function (a, b) {
    const nameA = a.node.childMarkdownRemark.frontmatter.date
    const nameB = b.node.childMarkdownRemark.frontmatter.date
    if (nameA < nameB) {
      return 1
    }
    if (nameA > nameB) {
      return -1
    }

    // names must be equal
    return 0
  })

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Performances" />
      <div className=" [ wrapper ] ">
        <div className=" [ accent-line-two-rows ] ">
          <h2>Upcoming Performances</h2>
        </div>
        <div className=" [ flow ] ">
		<Filler />
          {performance.map(({ node }) => {
            if (node.childMarkdownRemark.frontmatter.date >= getCurrentDate()) {
              return (
                <EventCard
                  key={node.childMarkdownRemark.fields.slug}
                  date={node.childMarkdownRemark.frontmatter.displaydate}
                  day={node.childMarkdownRemark.frontmatter.day}
                  month={node.childMarkdownRemark.frontmatter.month}
                  year={node.childMarkdownRemark.frontmatter.year}
                  slug={node.childMarkdownRemark.fields.slug}
                  performance_name={
                    node.childMarkdownRemark.frontmatter.performance_name
                  }
                  company={node.childMarkdownRemark.frontmatter.company}
                  description={node.childMarkdownRemark.frontmatter.description}
                  excerpt={node.childMarkdownRemark.excerpt}
                  ticket_link={node.childMarkdownRemark.frontmatter.ticket_link}
                  time={node.childMarkdownRemark.frontmatter.time}
                  preview_image={
                    node.childMarkdownRemark.frontmatter.preview_image
                      .childImageSharp.fluid
                  }
                  more_details={true}
                  utility_class="hover-shadow"
                />
              )
            } else {
              return null
            }
          })}
        </div>
        <div className=" [ accent-line-two-rows ] ">
          <h2>Past Performances</h2>
        </div>
        <div className=" [ flow ] ">
          {reversePerformance.map(({ node }) => {
            if (node.childMarkdownRemark.frontmatter.date < getCurrentDate()) {
              return (
                <PastEventCard
                  key={node.childMarkdownRemark.fields.slug}
                  date={node.childMarkdownRemark.frontmatter.displaydate}
                  slug={node.childMarkdownRemark.fields.slug}
                  performance_name={
                    node.childMarkdownRemark.frontmatter.performance_name
                  }
                  company={node.childMarkdownRemark.frontmatter.company}
                  more_details={false}
                  utility_class="padding"
                />
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Performances

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    descend: allFile(
      filter: {
        childMarkdownRemark: {
          frontmatter: { post_type: { eq: "performance" } }
        }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY-MM-DD")
              displaydate: date(formatString: "D MMMM YYYY")
              year: date(formatString: "YYYY")
              month: date(formatString: "MMM")
              day: date(formatString: "D")
              time
              description
              ticket_link
              performance_name
              company
              preview_image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    ascend: allFile(
      filter: {
        childMarkdownRemark: {
          frontmatter: { post_type: { eq: "performance" } }
        }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY-MM-DD")
              displaydate: date(formatString: "D MMMM YYYY")
              year: date(formatString: "YYYY")
              month: date(formatString: "MMM")
              day: date(formatString: "D")
              time
              description
              ticket_link
              performance_name
              company
              preview_image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
